.font-11 {
  font-size: 11px !important;
}

.font-xs-14 {
  @include media-breakpoint-down(md) {
    font-size: 14px !important;
  }
}

.font-16 {
  font-size: 16px !important;
}

.font-sm-16 {
  @include media-breakpoint-up(sm) {
    font-size: 16px !important;
  }
}

.font-18 {
  font-size: 18px !important;
}

.font-lg-18 {
  @include media-breakpoint-up(lg) {
    font-size: 18px !important;
  }
}

.font-20 {
  font-size: 20px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-lg-24 {
  @include media-breakpoint-up(lg) {
    font-size: 24px !important;
  }
}

.font-sm-32 {
  @include media-breakpoint-up(lg) {
    font-size: 32px !important;
  }
}

.font-48 {
  font-size: 48px !important;
}

.font-40 {
  font-size: 40px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-weight-semibold {
  font-weight: 500 !important;
}

.font-weight-extrabold {
  font-weight: 800 !important;
}

.btn-inline {
  display: inline-block !important;
}

.text-underline {
  text-decoration: underline;
}

.text-gray {
  color: $gray;
}

.bg-grid {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAA1SURBVHgB7c8xEQAwDMSwtFNhPH+UvaDIEBmBfJK82lrP3xoOAAAAAAAAAAAAAAAAAGAc8AGnLAEOXIVVCAAAAABJRU5ErkJggg=="), #F9FBFE;
}

.bg-size-10 {
  background-size: 10% !important;
}

.h-100 {
  height: 100% !important;
}

.pt-32 {
  padding-top: 32px !important;
}

.pb-32 {
  padding-bottom: 32px !important;
}

.pb-xs-24 {
  @include media-breakpoint-up(sm) {
    padding-bottom: 24px !important;
  }
}

.py-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.py-xl {
  @include media-breakpoint-up(sm) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 96px;
    padding-bottom: 96px;
  }
}

.letter-spacing-16 {
  letter-spacing: 0.16rem !important;
}

.letter-spacing-24 {
  letter-spacing: 0.16rem !important;
}

.c-pointer {
  cursor: pointer;
}

.outline-0 {
  outline: none !important;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}
